import { formatNumberToMoney } from "@/utils/cart";
import { AddonCartItem, CartItem } from "../models/Cart";
import { TicketType } from "../models/Tickets";

interface Props {
  cart: Array<CartItem>;
  addonCart: Array<AddonCartItem>;
  hideTitle?: boolean;
}

export const CartItems = ({ cart, addonCart, hideTitle }: Props) => {
  return (
    <div className="mb-3">
      {!hideTitle && (
        <h2
          id="summary-heading"
          className="text-lg font-medium text-gray-900 -mb-7 lg:mb-0"
        >
          Order summary
        </h2>
      )}
      <ul role="list" className="text-sm font-medium text-gray-900 mt-4">
        {/* The tickets */}
        {cart.map((cartItem: CartItem) => (
          <li
            key={cartItem.ticketUuid}
            className="flex items-start space-x-4 py-2"
          >
            <p className="text-gray-500 dark:text-gray-400">
              x{cartItem.quantity}
            </p>
            <div className="flex-auto space-y-1">
              <h3 className="dark:text-gray-400">{cartItem.name}</h3>
              {cartItem.ticketType === TicketType.PAYMENT_PLAN &&
                cartItem.perMonthPrice && (
                  <p className="text-gray-500">
                    {cartItem.numberOfMonths} monthly payments of{" "}
                    {formatNumberToMoney(
                      parseFloat(cartItem.perMonthPrice) * cartItem.quantity
                    )}
                  </p>
                )}
            </div>
            <p className="flex-none text-base font-medium dark:text-gray-400">
              {formatNumberToMoney(cartItem.lineTotal)}
            </p>
          </li>
        ))}
        {/* The addons */}
        {addonCart.map((addonCartItem: AddonCartItem) => (
          <li
            key={addonCartItem.uuid}
            className="flex items-start space-x-4 py-2"
          >
            <p className="text-gray-500">x{addonCartItem.quantity}</p>
            <div className="flex-auto space-y-1">
              <h3>{addonCartItem.name}</h3>
            </div>
            <p className="flex-none text-base font-medium">
              &euro;{addonCartItem.lineTotal.toFixed(2)}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};
