import { Event } from "@/models/Cart";
import { dateToPortalDisplay } from "@/utils/date";
import { parseISO } from "date-fns/esm";

interface Props {
  event?: Event | null;
}

export const NewEventDetails = ({ event }: Props) => {
  return (
    <div className="flex flex-1 items-center">
      {event ? (
        <>
          <div>
            <img
              src="/icon.jpeg"
              alt="icon"
              className="w-16 h-16 rounded-lg border dark:border-white/5 border-transparent"
            />
          </div>
          <div className="ml-3">
            <h3 className="scroll-m-20 text-xl font-semibold tracking-tight dark:text-white -mt-0.5">
              {event.name}
            </h3>
            <p className="text-xs text-muted-foreground text-orange-500 -mt-0.5">
              {dateToPortalDisplay(parseISO(event.startDate))}
            </p>
            <p className="text-xs text-muted-foreground dark:text-gray-400">
              {event.location}
            </p>
          </div>
        </>
      ) : (
        <>
          <div className="w-16 h-16 rounded-lg border border-transparent bg-zinc-200 dark:bg-dark-secondary animate-pulse"></div>
          <div className="ml-3 animate-pulse">
            <div className="dark:text-white -mt-0.5 w-52 rounded-sm h-5 bg-zinc-200 dark:bg-dark-secondary dark:border-white/5 border-transparent bg-dark-secondary">
              &nbsp;
            </div>
            <div className="dark:text-white mt-1 w-40 rounded-sm h-3 bg-zinc-200 dark:bg-dark-secondary dark:border-white/5 border-transparent bg-dark-secondary">
              &nbsp;
            </div>
            <div className="dark:text-white mt-1 w-72 rounded-sm h-3 bg-zinc-200 dark:bg-dark-secondary dark:border-white/5 border-transparent bg-dark-secondary">
              &nbsp;
            </div>
          </div>
        </>
      )}
    </div>
  );
};
