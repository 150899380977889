import { AddonCartItem, CartItem } from "@/models/Cart";
import { cartActions } from "@/store/cart";
import { State } from "@/store/store";
import {
  formatNumberToMoney,
  getCartFees,
  getCartSubTotal,
  getNumberOfTicketsInCart,
} from "@/utils/cart";
import { ShoppingCartIcon } from "@heroicons/react/20/solid";
import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CartItems } from "../CartItems";
import { CartTotals } from "./CartTotals";
import { Button } from "../ui/button";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

interface Props {
  hideCart?: boolean;
  cart: Array<CartItem>;
  addonCart: Array<AddonCartItem>;
  leftContent?: ReactNode;
  rightContent: ReactNode;
}

export const CartNav = ({
  hideCart,
  cart,
  addonCart,
  leftContent,
  rightContent,
}: Props) => {
  const dispatch = useDispatch();
  const numberOfTickets = getNumberOfTicketsInCart(cart);
  const showCart = useSelector((state: State) => state.cart.showCart);
  const ticketLabel = numberOfTickets === 1 ? "ticket" : "tickets";
  const order = useSelector((state: State) => state.cart.order);
  const carbonFootprintContribution = useSelector(
    (state: State) => state.cart.carbonFootprintContribution
  );
  const totalDiscount = useSelector((state: State) => state.cart.totalDiscount);

  return (
    // <div className="rounded-lg border bg-card text-card-foreground shadow-sm mb-4 flex p-2">
    <div>
      <div className="rounded-lg bg-primary border dark:border-white/5 text-primary-foreground text-card-foreground shadow-sm mb-4 flex p-2 h-[3.25rem]">
        <div className={`${order ? "" : "w-1/3"}`}>{leftContent}</div>
        {!hideCart && (
          <button
            className={`flex items-center cursor-pointer ${
              order
                ? "w-1/2 justify-start ml-2"
                : rightContent
                ? "w-1/2 justify-center"
                : "flex-1 justify-end pr-1"
            }`}
            onClick={() => {
              dispatch(cartActions.SetShowCart({ showCart: !showCart }));
            }}
          >
            <ShoppingCartIcon className="h-4 w-4 mr-1.5" aria-hidden="true" />
            <p className="text-sm font-medium leading-none">
              {getNumberOfTicketsInCart(cart)} {ticketLabel} (
              {formatNumberToMoney(
                getCartSubTotal(cart) +
                  getCartFees(cart) +
                  carbonFootprintContribution -
                  totalDiscount
              )}
              )
            </p>
            {showCart ? (
              <ChevronUpIcon className="h-4 w-4 ml-1.5" aria-hidden="true" />
            ) : (
              <ChevronDownIcon className="h-4 w-4 ml-1.5" aria-hidden="true" />
            )}
          </button>
        )}
        <div
          className={`${
            order
              ? "w-1/2 lg:w-full justify-end lg:justify-center"
              : `flex justify-end ${rightContent ? "w-1/3" : ""}`
          }`}
        >
          {rightContent}
        </div>
      </div>

      {showCart && (
        <div className={`px-1 pb-6 ${order ? "lg:hidden" : ""}`}>
          <CartItems cart={cart} addonCart={addonCart} hideTitle={true} />
          <CartTotals />
        </div>
      )}
    </div>
  );
};
